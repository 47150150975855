import React, { useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Slide = () => {
  useEffect(() => {
    // Explicitly initialize MDB UI Kit components
    const { Carousel } = require("mdb-ui-kit");
    const myCarousel = document.getElementById("carouselVideoExample");
    if (myCarousel) {
      new Carousel(myCarousel); // Initialize the carousel
      console.log("find");
      myCarousel.addEventListener("slide.bs.carousel", (event) => {
        console.log("slide");
      });
    }
  }, []);

  return (
    <div>
      <section
        id="project_demo_section_tobe_linked"
        className="slide-videos d-flex flex-row justify-content-center align-items-center"
      >
        <div className="slide container my-auto">
          <div
            id="carouselVideoExample"
            data-mdb-carousel-init
            className="carousel slide carousel-fade"
            // data-mdb-ride="carousel"
            data-mdb-ride="false"
            data-bs-interval="false"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-mdb-button-init
                data-mdb-target="#carouselVideoExample"
                data-mdb-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-mdb-button-init
                data-mdb-target="#carouselVideoExample"
                data-mdb-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-mdb-button-init
                data-mdb-target="#carouselVideoExample"
                data-mdb-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>

            <div id="project-video" className="carousel-inner spot-alert">
              <div className="carousel-item active">
                <video className="d-block w-100" controls>
                  <source
                    src={require("../spotAlert-demo.mp4")}
                    type="video/mp4"
                  />
                </video>
                <div className="carousel-caption d-none d-md-block">
                  <h5
                    style={{
                      color: "rgb(211, 142, 9)",
                      fontSize: "1.3rem",
                      fontWeight: 600,
                    }}
                  >
                    SpotAlert Demo
                  </h5>
                  <p
                    style={{
                      color: "rgb(211, 142, 9)",
                      fontSize: "1.2rem",
                    }}
                  >
                    Techs: Java | React | BootStrap | MongoDB | JavaScript |
                    Spring Boot
                  </p>
                </div>
              </div>

              <div className="carousel-item">
                {/* <video className="img-fluid" autoPlay loop muted> */}
                <video className="d-block w-100" controls>
                  <source
                    src={require("../financetrack-demo.mp4")}
                    type="video/mp4"
                  />
                </video>
                <div className="carousel-caption d-none d-md-block">
                  <h5
                    style={{
                      color: "rgb(0, 0, 0)",
                      fontSize: "1.3rem",
                      fontWeight: 600,
                    }}
                  >
                    FinanceTrack Demo
                  </h5>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      fontSize: "1.2rem",
                    }}
                  >
                    Techs: Python | JavaScript | HTML | CSS | SQLite |Flask
                  </p>
                </div>
              </div>

              <div className="carousel-item">
                {/* <video className="img-fluid" autoPlay loop muted> */}
                <video className="d-block w-100" controls>
                  <source
                    src={require("../easyremind-demo.mp4")}
                    type="video/mp4"
                  />
                </video>
                <div className="carousel-caption d-none d-md-block">
                  <h5
                    style={{
                      color: "rgb(0, 0, 0)",
                      fontSize: "1.3rem",
                      fontWeight: 600,
                    }}
                  >
                    EasyRemind Demo
                  </h5>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      fontSize: "1.2rem",
                    }}
                  >
                    Techs: Node.js | JavaScript | BootStrap | CSS | EJS |
                    Express | MySQL
                  </p>
                </div>
              </div>
            </div>

            <button
              data-mdb-button-init
              className="carousel-control-prev"
              type="button"
              data-mdb-target="#carouselVideoExample"
              data-mdb-slide="prev"
              style={{
                height: "80%",
                margin: "auto",
                color: "rgb(156, 61, 84,0.5)",
              }}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              data-mdb-button-init
              className="carousel-control-next"
              type="button"
              data-mdb-target="#carouselVideoExample"
              data-mdb-slide="next"
              style={{
                height: "80%",
                margin: "auto",
                color: "rgb(156, 61, 84,0.5)",
              }}
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Slide;
