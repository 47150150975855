import React from "react";
import personal from "../personal.png";

const About = () => {
  return (
    <div>
      <section
        id="about_me_section_tobe_linked"
        className="about d-flex justify-content-center align-items-center"
      >
        <div className="container">
          <div className="row  gx-md-4 d-flex align-items-center justify-content-center">
            <div className="about-image col-md-4 mb-5">
              <img
                src={personal}
                className="img-fluid"
                alt="Sarah's profile"
              ></img>
            </div>
            <div className="about-text col-md-8 text-center">
              <p>
                <ul>
                  <li>
                    Developed web applications using Python/Java/Node.js,
                    incorporating Flask/Spring Boot/Express for the web
                    framework, with a strong emphasis on object-oriented
                    programming
                  </li>
                  <li>
                    Created unit tests with Pytest and Unittest, utilizing mock,
                    fixture, and patch modules • Managed data in CSV, JSON,
                    NDJSON formats
                  </li>
                  <li>
                    Scripted for Linux (Bash) and Windows (PowerShell)
                    environments
                  </li>
                  <li>Employed Git for version control</li>
                  <li>Executed DDL, DML, and DQL operations with MySQL</li>
                  <li>
                    Connect, shape combine and visualize data using PowerBI
                  </li>
                  <li>
                    Developed games using JavaScript, HTML, and CSS, integrating
                    asynchronous functions and manipulating the DOM
                  </li>
                  <li>Leveraged Agile Scrum Method in Team Project</li>
                  <li>
                    Configured local area networks with static and dynamic
                    routing, packet filtering, and IPv4/IPv6 addressing
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
