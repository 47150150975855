import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="footer text-center text-lg-start  ">
        <div className="container-fluid">
          <div className="row d-flex flex-column flex-lg-row justify-content-between">
            <div className="d-flex flex-column col-lg-6 col-xl-3">
              <div className="d-none d-lg-block mt-5">
                <p>Get connected with me on social networks:</p>
              </div>

              <div className="footer-links">
                <a
                  href="https://www.linkedin.com/in/sarah-xiaoting-li"
                  className="me-4 text-reset"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://github.com/SarahLee29"
                  className="me-4 text-reset"
                >
                  <i className="fab fa-github"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-xl-3 mt-5">
              <h6 className="text-uppercase fw-bold mb-4"> Contact</h6>
              <p>
                <i className="fas fa-home me-3"></i> Vancouver, BC V6Y4M1, CA
              </p>
              <p>
                <i className="fas fa-envelope me-3"></i> xli267@my.bcit.ca
              </p>
              <p>
                <i className="fas fa-phone me-3"></i> + 01 672 513 5501
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
