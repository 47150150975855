import React from "react";
import logo from "../Designer.png";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img className="logo" src={logo} alt="logo"></img>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item active ms-auto">
                <a className="nav-link" href="/">
                  Home <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item ms-auto">
                <a className="nav-link" href="#about_me_section_tobe_linked">
                  About me
                </a>
              </li>
              <li className="nav-item ms-auto">
                <a className="nav-link" href="#projects_section_tobe_linked">
                  Projects
                </a>
              </li>
              <li className="nav-item ms-auto">
                <a
                  className="nav-link"
                  href="#project_demo_section_tobe_linked"
                >
                  Project Demos
                </a>
              </li>
              <li className="nav-item ms-auto">
                <a className="nav-link" href="#skills_section_tobe_linked">
                  Skills
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
