import React from "react";
import spotalertCard from "../spotalert-card.png";
import financetrackCard from "../financetrack.png";
import easyremindCard from "../easyremind.png";
import storemasterCard from "../storemaster.png";

const Projects = () => {
  return (
    <div>
      <section id="projects_section_tobe_linked" className="projects">
        <div className="container">
          <div className="spot-alert">
            <div className="card container">
              <div className="row">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                  <img
                    src={spotalertCard}
                    className="card-img-top"
                    alt="SpotAlert-intro"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>

                <div className="card-body d-flex flex-column flex-lg-row col-12 col-lg-8">
                  <div className="col-12 col-lg-5 px-2">
                    <h5 className="card-title">SpotAlert</h5>
                    <p
                      className="card-text pt-2"
                      style={{ fontSize: "1.2rem" }}
                    >
                      SpotAlert is a web application that instantly notifies
                      users about available time slots on the ICBC website for
                      road test bookings, enabling them to secure spots
                      promptly. It automatically sends regular timed requests to
                      check for available slots, ensuring continuous monitoring
                      and timely updates.
                    </p>
                  </div>
                  <div className="col-12 col-lg-7 d-flex flex-column pt-5 mx-2">
                    <div>
                      <ul className="list-group">
                        <li className="list-group-item text-project-alert">
                          Utilized push notifications with Firebase Cloud
                          Messaging
                        </li>
                        <li className="list-group-item text-project-alert">
                          Implemented stateless authentication with Spring
                          Security and JWT tokens
                        </li>
                        <li className="list-group-item text-project-alert">
                          Fetched data through RESTful APIs
                        </li>
                        <li className="list-group-item text-project-alert">
                          Connected to MongoDB to establish database
                        </li>
                      </ul>
                    </div>
                    <div className="card-button-div mt-3 d-flex justify-content-center">
                      <a href="#project-video" className="btn btn-primary">
                        Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="finance-track mt-3">
            <div className="card container">
              <div className="row">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                  <img
                    src={financetrackCard}
                    className="card-img-top"
                    alt="FinanceTrack-intro"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>

                <div className="card-body d-flex flex-column flex-lg-row col-12 col-lg-8">
                  <div className="col-12 col-lg-5 px-2">
                    <h5 className="card-title">FinanceTrack</h5>
                    <p
                      className="card-text pt-2"
                      style={{ fontSize: "1.2rem" }}
                    >
                      FinanceTrack is a collaborative project developed by a
                      6-member team. It empowers users to manage their financial
                      records, enabling them to perform CRUD operations on both
                      income and expense transactions. FinanceTrack also allows
                      users to share budgets with specific individuals,
                      facilitating joint contributions to shared savings plans.
                    </p>
                  </div>
                  <div className="col-12 col-lg-7 d-flex flex-column pt-5 mx-2">
                    <div>
                      <ul className="list-group">
                        <li className="list-group-item text-project-alert">
                          Wrote unit tests and functional tests to verify the
                          correctness and improve code coverage
                        </li>
                        <li className="list-group-item text-project-alert">
                          Reviewed peers’ codes, and wrote unit tests and
                          functional tests
                        </li>
                        <li className="list-group-item text-project-alert">
                          Configured Nginx as a reverse proxy to enhance
                          application security
                        </li>
                        <li className="list-group-item text-project-alert">
                          Practiced Agile Methodologies & employed Git version
                          control tool
                        </li>

                        <li className="list-group-item text-project-alert">
                          Implemented CI with GitHub actions and CD with AWS EC2
                        </li>
                      </ul>
                    </div>
                    <div className="card-button-div mt-3 d-flex justify-content-center">
                      <a href="#project-video" className="btn btn-primary">
                        Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="easy-remind mt-3">
            <div className="card container">
              <div className="row">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                  <img
                    src={easyremindCard}
                    className="card-img-top"
                    alt="EasyRemind-intro"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>

                <div className="card-body d-flex flex-column flex-lg-row col-12 col-lg-8">
                  <div className="col-12 col-lg-5 px-2">
                    <h5 className="card-title">EasyRemind</h5>
                    <p
                      className="card-text pt-2"
                      style={{ fontSize: "1.2rem" }}
                    >
                      EasyRemind is a web application designed to streamline the
                      management of reminders. Users can create, read, update,
                      and delete reminders, while administrators have the
                      ability to remotely revoke access for specific users.
                      Additionally, images are displayed based on reminder
                      keywords, providing users with a visual aid to enhance
                      memory.
                    </p>
                  </div>
                  <div className="col-12 col-lg-7 d-flex flex-column pt-5 mx-2">
                    <div>
                      <ul className="list-group">
                        <li className="list-group-item text-project-alert">
                          Connected to MySQL for database management
                        </li>
                        <li className="list-group-item text-project-alert">
                          Implemented an authentication system using Passport.js
                          middleware
                        </li>
                        <li className="list-group-item text-project-alert">
                          Integrated images from the Unsplash API
                        </li>
                        <li className="list-group-item text-project-alert">
                          Implemented multiple authentication options, including
                          Google and GitHub login, as well as traditional email
                          and password combinations
                        </li>
                      </ul>
                    </div>
                    <div className="card-button-div mt-3 d-flex justify-content-center">
                      <a href="#project-video" className="btn btn-primary">
                        Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="store-master mt-3">
            <div className="card container">
              <div className="row">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                  <img
                    src={storemasterCard}
                    className="card-img-top"
                    alt="StoreMaster-intro"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>

                <div className="card-body d-flex flex-column flex-lg-row col-12 col-lg-8">
                  <div className="col-12 col-lg-5 px-2">
                    <h5 className="card-title">StoreMaster</h5>
                    <p
                      className="card-text pt-2"
                      style={{ fontSize: "1.2rem" }}
                    >
                      StoreMaster is an application that allows users to
                      interact with it via API endpoints to retrieve and manage
                      data. Users can manage stores by listing orders,
                      customers, and products, placing orders, selecting
                      placement strategies, and performing other CRUD operations
                      through RESTful APIs.
                    </p>
                  </div>
                  <div className="col-12 col-lg-7 d-flex flex-column pt-5 mx-2">
                    <div>
                      <ul className="list-group">
                        <li className="list-group-item text-project-alert">
                          Designed APIs utilizing various HTTP methods (GET,
                          PUT, POST, DELETE), both with and without input
                          parameters
                        </li>
                        <li className="list-group-item text-project-alert">
                          Implemented row-level security at the application
                          level using SQLAlchemay to allow users to access their
                          own data only
                        </li>
                      </ul>
                    </div>
                    <div className="card-button-div mt-3 d-flex justify-content-center">
                      <a
                        href="https://github.com/SarahLee29/StoreMaster.git"
                        className="btn btn-primary"
                      >
                        source code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Projects;
